import React from "react";
import Modal from 'react-responsive-modal';

class WhiteLabelModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: true
		};
	}

	onCloseModal = () => {
		this.setState({open: false});
	};

	whiteLabelConfirm = () => {
			console.log(this.props);
			fetch(this.props.baseUrl + `/Files/SetWhiteLabel?LCFileID=${this.props.lcFileID}&filename=${this.props.fileName}`, {
				method: 'PATCH'
			})
			.then((response) => {
				this.setState({open: false});
			})
			.then( ( data ) => {
				this.setState({open: false});
			})
			.catch((error) => {
				this.setState({open: false});
			});
	};

	whiteLabelDeny = () => {
		this.setState({open: false});
	};

	render() {
		const {open} = this.state;
		return (
			<Modal modalId="whitelabel-modal" showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false} center={true} open={open} onClose={this.onCloseModal}>
				<div className="grid-x align-center">
					<div className="small-12 cell text-center background-white">
						<h3>Would you like to create a white label scan?</h3>
                        <p><button className="button" onClick={this.whiteLabelConfirm}>Yes</button> <button href="#" className="button alert" onClick={this.whiteLabelDeny}>No</button></p>
                        <br />
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td align="right" style={{ width: '40%' }}>Example:</td>
                                <td align="left" style={{ width: '60%' }}><a href="https://freelistcheck.com/Report?id=4522&u=3178&g=5cfc47eb-c8f2-4b74-a06c-5009fe50366f" target="_blank" rel="noopener noreferrer">Standard Report</a></td>
                            </tr>                            
                            <tr>
                                <td align="right">Example:</td>
                                <td align="left"><a href="https://freelistcheck.com/Report?id=4544&u=3520&g=c8b3bdfb-06dc-4380-af8a-f044aee75bf1" target="_blank" rel="noopener noreferrer">White Labeled Report</a></td>
                            </tr>
                        </table>                        
					</div>
				</div>
			</Modal>
		);
	}
}

export default WhiteLabelModal
