import React from 'react'
import LogoWhite from '../images/logo-white-atdata.png'

class HealthScanBanner extends React.Component {
	render() {
		return (
			<div id="health-scan-banner" className="background-primary color-white padding-vertical">
				<div className="grid-container">
					<div className="grid-x grid-padding-x align-center align-middle text-center">
						<div className="small-12 medium-shrink cell">
							<img src={LogoWhite} alt="Fresh Address Logo" />
						</div>
						<div className="small-12 medium-shrink cell">
							<h2>A Health Scan for your Email</h2>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HealthScanBanner;
