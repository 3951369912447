import React from 'react'
import WhiteLabelModal from "./whitelabel-modal";

export default class ListUploadSuccess extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				{
					this.props.showWhiteLabelPrompt &&

					<WhiteLabelModal
						baseUrl={this.props.baseUrl}
						lcFileID={this.props.lcFileID}
						fileName={this.props.fileName}
					/>
				}

				<p>{this.props.successMessage}</p>
			</>
		);
	}
}
