import React from 'react'
import { Field, Form, FormProvider } from 'react-advanced-form'
import rules from './validation/validation-rules'
import messages from './validation/validation-messages'
import Input from "./fields/Input"
import Select from "./fields/Select"
import Radio from "./fields/Radio"
import Phone from './fields/Phone'

export default class ListUploadRegister extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            first: "",
            last: "",
            email: "",
            company: "",
            title: "",
            phone: "",
            HeardFrom: "",
            listsize: "",
            comment:"",
            mktg: "",
            isBtnDisabled: false,
        }
    }

    render() {
        return (
            <div className="padding-all-small">
                <p>
                    <strong>One More Step</strong><br />
                    Please complete the form below to process your list
                </p>

                <FormProvider messages={messages}>
                    <Form
                        className="margin-bottom list-upload-form text-left large-padding-horizontal-large"
                        action={this.props.handleNewUserRegistration}>
                        <Field.Group name="primaryInfo">
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 medium-6 cell">
                                    <Input
                                        label="First name"
                                        name="first"
                                        type="text"
                                        value={this.props.first}
                                        onChange={this.props.handleInputChange}
                                        required />
                                </div>

                                <div className="small-12 medium-6 cell">
                                    <Input
                                        label="Last name"
                                        name="last"
                                        type="text"
                                        value={this.props.last}
                                        onChange={this.props.handleInputChange}
                                        required />
                                </div>

                                <div className="small-12 cell">
                                    <Input
                                        label="E-mail"
                                        name="email"
                                        type="email"
                                        value={this.props.email}
                                        onChange={this.props.handleEmailAddressChange}
                                        disabled={this.props.email}                                        
                                        required />
                                </div>

                                <div className="small-12 cell">
                                    <Input
                                        label="Company"
                                        name="company"
                                        type="text"
                                        value={this.props.company}
                                        onChange={this.props.handleInputChange}
                                        required />
                                </div>

                                <div className="small-12 cell">
                                    <Input
                                        label="Title"
                                        name="title"
                                        type="text"
                                        value={this.props.title}
                                        onChange={this.props.handleInputChange}
                                        required />
                                </div>

                                <div className="small-12 cell">
                                    <Phone
                                        label="Phone"
                                        name="phone"
                                        type="text"
                                        value={this.props.phone}
                                        onChange={this.props.handleInputChange}
                                    />
                                </div>

                                <div className="small-12 cell">
                                    <Select
                                        label="How did you hear about us?"
                                        name="HeardFrom"
                                        value={this.props.HeardFrom}
                                        onChange={this.props.handleInputChange}
                                        required
                                    >
                                        <option>Select an Option</option>
                                        <option value="Current/Former Client">Current/Former Client</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="FreshAddress Blog">FreshAddress Blog</option>
                                        <option value="FreshAddress Newsletter">FreshAddress Newsletter</option>
                                        <option value="Google Ads">Google Ads</option>
                                        <option value="Google Search">Google Search</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="News Article">News Article</option>
                                        <option value="OnlyInfluencers Blog">OnlyInfluencers Blog</option>
                                        <option value="Press Release">Press Release</option>
                                        <option value="Referral, Word of Mouth">Referral, Word of Mouth</option>
                                        <option value="Referral, ESP">Referral, ESP</option>
                                        <option value="Twitter">Twitter</option>
                                        <option value="Tradeshow">Tradeshow</option>
                                        <option value="Webinar">Webinar</option>
                                        <option value="Other">Other</option>

                                    </Select>
                                </div>

                                <div className="small-12 cell">
                                    <Select
                                        label="What's your database size?"
                                        name="listsize"
                                        value={this.props.listsize}
                                        onChange={this.props.handleInputChange}
                                    >
                                        <option>Select an Option</option>
                                        <option value="&lt; 100k">&lt; 100k</option>
                                        <option value="100-500k">100-500k</option>
                                        <option value="500k-1mm">500k-1mm</option>
                                        <option value="1mm-5mm">1mm-5mm</option>
                                        <option value="&gt; 5mm">&gt; 5mm</option>
                                    </Select>
                                </div>

                                <div className="small-12 cell">
                                    <Input
                                        label="Comment"
                                        name="comment"
                                        type="text"
                                        value={this.props.comment}
                                        onChange={this.props.handleInputChange}
                                        />
                                </div>

                                <div className="small-12 cell margin-vertical-small">
                                    <strong>Stay in the know</strong>
                                    <Radio
                                        label="Yes, please! Sign me up for the monthly FreshPerspectives newsletter, product announcements, webinar invitations, and current promotions."
                                        name="mktg"
                                        value="All"
                                        checked={this.props.mktg === 'All'}
                                        onChange={this.props.handleInputChange}
                                    />
                                    <Radio
                                        label="No thank you. Not right now."
                                        name="mktg"
                                        value="No"
                                        checked={this.props.mktg === 'No'}
                                        onChange={this.props.handleInputChange}
                                    />
                                </div>

                                <div className="small-12 cell text-center">
                                    <button type="submit">Register &amp; Process My List</button>
                                </div>
                            </div>
                        </Field.Group>
                    </Form>
                </FormProvider>
            </div>
        );
    }
}
