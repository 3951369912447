import React from "react"
import PropTypes from "prop-types";

class Loader extends React.Component {
	static propTypes = {
		message: PropTypes.string,
	}

	render() {
		const { message } = this.props

		return (
			<div className="loader-container grid-x align-middle align-center padding-bottom">
				<span className="icon-loading color-secondary cell shrink"></span> <strong className="cell shrink padding-left-small">{message}</strong>
			</div>
		);
	}
}

export default Loader
