import React from 'react';
import mailbox from '../images/icons/invalids-icon-v2.svg'
import spamtrap from '../images/icons/spamtraps-icon-v2.svg'
import forced from '../images/icons/bogus-icon-v2.svg'

class ListWarningsShort extends React.Component {
	render() {
		return (
			<div id="list-check-warnings" className="list-check-warnings padding-vertical-large grid-container">
				<div className="grid-x text-center">
					<div className="small-12 cell">
						<h2>We notify you if your list contains any warnings signs of...</h2>
					</div>
				</div>

				<div className="text-center">
					<div className="list-check-warnings grid-x text-center margin-top">
						<div className="list-check-warning small-12 medium-4 cell padding-vertical">
							<img src={mailbox} alt="Mailbox" height={80}/>
							<h3>Invalids</h3>
						</div>

						<div className="list-check-warning small-12 medium-4 cell padding-vertical">
							<img src={spamtrap} alt="Spamtraps" height={80}/>
							<h3>Spamtraps</h3>
						</div>

						<div className="list-check-warning small-12 medium-4 cell padding-vertical">
							<img src={forced} alt="Bogus and Disposables" height={80}/>
							<h3>Bogus and Disposables</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ListWarningsShort;
