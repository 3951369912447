import React from 'react'
import NumberFormat from "react-number-format";
import ListUploadError from "./list-upload-error";

class ListUploadInfo extends React.Component {

  /**
   * Show a success or error message depending on our length of file upload errors
   *
   * @param errors
   * @return {string}
   */
  getIcon( errors ) {

    let icon = 'success';

    if ( errors.length > 0  ) {
      icon = 'alert';
    }

    return 'icon-' + icon;
  }

	render() {

		return (
      <div>
        { ( this.props.fileUploadErrors.length > 0 || this.props.passedValidation !== null ) &&
          <div>
            <h3>{this.props.processingStatus}</h3>
            { this.props.emailRecords ? (
              <div>
                <span className={ this.getIcon( this.props.fileUploadErrors ) }></span>
                <p className="num-of-records">
                  <NumberFormat
                    thousandSeparator={true}
                    value={this.props.emailRecords}
                    suffix=" Records"
                    displayType={'text'} /> to process in {this.props.files[0].name}
                </p>
                <ListUploadError fileUploadErrors={this.props.fileUploadErrors} />
              </div>
			) : (
				<ListUploadError fileUploadErrors={this.props.fileUploadErrors} />
			)
            }
          </div>
        }
      </div>
		);
	}
}

export default ListUploadInfo;
