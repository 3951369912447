import React from 'react';

class ListUploadError extends React.Component {

	render() {

    const errors = this.props.fileUploadErrors.map( error => (
        <li key={error}>{error}</li>
      ));

		return (
		  <div>
        { this.props.fileUploadErrors.length > 0 &&
          <div className="error-bar">
            <ul>
              {errors}
            </ul>
          </div>
        }
      </div>
		);
	}
}

export default ListUploadError;
