import React from 'react'
import Dropzone from 'react-dropzone'
import Loader from "./loader"

class ListUploadStart extends React.Component {

  render() {

    const files = this.props.files.map(file => (
      <li key={file.name}>
	      <span className="file-name">{file.name}</span><span className="file-size">{file.size} bytes</span>
      </li>
    ));

    return (
      <div className="container">
		  {
			  ! this.props.loading ? (
			    <div>
            {
              ! this.props.successMessage && <Dropzone onDrop={this.props.onFileDrop} accept={'text/plain, .txt'} multiple={false}>
                {({getRootProps, getInputProps, acceptedFiles,
                    isDragActive,
                    isDragAccept,
                    isDragReject}) => (
                  <section className="container">
                    { this.props.fileUploadErrors.length === 0 &&
                    <div {...getRootProps({className:'dropzone'})}>
                      <input {...getInputProps()} />
                      { this.props.files.length > 0 && files ? (
                        <div>
                          <h3>Files</h3>
                          <div
                            className={
                              isDragActive ? "dropzone-area active" : "dropzone-area inactive"
                            }
                          >
                            <p><a className="replace-file" href={this.props.removeFiles}>Replace File</a></p>
                            <ul className="dropzone-file-list">{files}</ul>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h3>Get Started with Free List Check</h3>
                          <p className="step-header">
                            <span className="large">1.</span> <span
                            className="step-label">Upload your email list</span>
                          </p>
                          <div
                            className={
                              isDragActive ? "dropzone-area active" : "dropzone-area inactive"
                            }
                          >
                            <p className="lead margin-top">Drop files to upload</p>
                            or<br />
                            <span className="button margin-vertical large-margin-bottom">Select File</span>
                          </div>
                          <div className="small">
                            Only *.txt files are accepted<br/>
                            File should ONLY contain email addresses and no delimiters or other fields<br/>
                            Minimum of 1,000 records, maximum of 5,000,000 records<br/>
                            We will not store or cache your data, it will be deleted automatically after processing
                          </div>
                        </div>
                      )
                      }
                    </div>
                    }
                  </section>
                )}
              </Dropzone>
            }
          </div>
			  ) : (
				  <Loader message="Checking File" />
			  )
		  }

        { this.props.files.length > 0 && this.props.fileUploadErrors.length === 0  && ! this.props.successMessage  &&
          <p><button type="submit" onClick={this.props.handleFileUpload}>Upload Now</button></p>
        }
      </div>
    );
  }
}


export default ListUploadStart;
