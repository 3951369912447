import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ListUpload from "../../components/list-upload"
import ListWarningsShort from "../../components/list-warnings-short"
import FAQs from "../../components/faqs"
import HealthScanBanner from "../../components/health-scan-banner"

const StartPage = (props) => {
	const isScanButtonVisible = false;

	return (
		<Layout isScanButtonVisible={isScanButtonVisible}>
			<SEO title="Check My List" />

			<ListUpload />
			<ListWarningsShort />
			<HealthScanBanner />
			<FAQs />

		</Layout>
	);
};


export default StartPage
