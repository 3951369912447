import React from "react"
import ListUploadStart from "./list-upload-start"
import ListUploadProceed from "./list-upload-proceed"
import ListUploadInfo from "./list-upload-info"
import ListUploadSuccess from "./list-upload-success"

class ListUpload extends React.Component {

  constructor(props) {
    super(props);

    let currentUserURL = typeof window !== 'undefined' ? window.location.href : '';
    let urlPieces      = currentUserURL.split("/");
    let apiURL         = urlPieces[0] + "//" + urlPieces[2];
    this.baseUrl = apiURL;

    // Public Methods
    this.onFileDrop                = this.onFileDrop.bind(this);
    this.handleFileUpload          = this.handleFileUpload.bind(this);
    this.handleUploadReset         = this.handleUploadReset.bind(this);
    this.handleEmailConfirmation   = this.handleEmailConfirmation.bind(this);
    this.handleEmailAddressChange  = this.handleEmailAddressChange.bind(this);
    this.handleAgreeToTermsChange  = this.handleAgreeToTermsChange.bind(this);
    this.handleEmailReset          = this.handleEmailReset.bind(this);
    this.handleThatsNotMe          = this.handleThatsNotMe.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);

    this.handleUserRegister        = this.handleUserRegister.bind(this);
    this.handleNewUserRegistration = this.handleNewUserRegistration.bind(this);

    // Default State
    this.state = {
	  baseUrl: this.baseUrl,
      fileUploaded: false,
      fileUploadErrors: [],
      fileUploadSuccess: false,
      files: [],
      fileName: '',
      folderID: '',
      email: '',
      emailOK: null,
      suggestedEmail: null,
      suggestionChoice: '',
      agreeToTerms: false,
      emailRecords: '',
      userData: null,
      successMessage: '',
      rejectedReason: '',
      prompt: null,
      promptID: null,
	  showWhiteLabelPrompt: false,
	  passedValidation: null,
      processingStatus:"Good to Run",
      registerSuccss:true,
      first: '',
      last: '',
      company: '',
      title: '',
	  listsize: '',
      phone: '',
      HeardFrom: '',
      comment: '',
      mktg: '',
      lcUserID: '',
      lcFileID: '',
        loading: false,
        isBtnDisabled: false,
        processing: false
    }
  }

  /**
   * Handle drag or click of select file
   *
   * @param files
   */
  onFileDrop(files) {
    this.setState({
      files:files,
    });
  }

  /**
   * Handle when we have an error and we need to upload again
   * This method simply resets our state
   *
   * @param event
   */
  handleUploadReset(event) {

    event.preventDefault();

    this.setState({
        fileUploaded: false,
        fileUploadErrors: [],
        fileUploadSuccess: false,
        processingStatus: "Good to Run",
        prompt: null,
        files: [],
        successMessage: '',
        rejectedReason: '',
        promptID: null,
        passedValidation: null,
        loading: false,
        emailOK: null,
        suggestedEmail: null,
        suggestionChoice: '',
        isBtnDisabled: false,
    })
  };

  /**
   * Handle when a file is actually uploaded to the API
   * Simple Fetch/Multipart POST
   *
   * @param event
   */
  handleFileUpload(event) {
      event.preventDefault();

    this.setState( {
		loading: true,
	} );

    var formData = new FormData();
        formData.append('file', this.state.files[0] );

    fetch(this.baseUrl + '/Files/Upload', {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if ( ! contentType || ! contentType.includes('application/json')) {
          throw new TypeError("Oops, we haven't got JSON!");
        }
        return response.json();
      })
      .then( ( data ) => {

        // If we didn't pass set some of our error state
        if ( ! data.passedValidation || data.rejectedReason ) {
          this.setState({
              fileUploadErrors : [data.rejectedReason],
              passedValidation: data.passedValidation,
              processingStatus: 'Warning',
              loading: false,
          } );

        // If we passed, work normally
        } else {
          this.setState({
            fileUploadErrors: [],
            fileUploadSuccess: true,
            fileName: encodeURIComponent(data.fileName),
            folderID: data.folderID,
            emailRecords: parseInt( data.records, 10 ),
            passedValidation:data.passedValidation,
            loading: false,
          } )
        }
      })
      .catch((error) => {
          console.error('Error:', error);
          // Set error state
          this.setState({
              fileUploadErrors: ['There was an error with your file.  Please try to submit again.'],
              passedValidation: false,
              processingStatus: 'Warning',
              loading: false,
          });
      });
  };

  /**
   * Keep track of when our email address field changes
   *
   * @param event
   */
  handleEmailAddressChange = ({ nextValue }) => {
    this.setState({
      email: nextValue
    } );
  };

  /**
   * Handle the generic input change within forms (that do not need specific validation or other functionality)
   *
   * @param event
   * @param nextValue
   * @param fieldProps
   */
  handleInputChange = ({event, nextValue, fieldProps}) => {

    // If the event is undefined the die early, this is in place due to a timing issues that can occur
    if ( typeof event === 'undefined' ) {
      return;
    }

    const target = event.target;
    const value  = nextValue;
    const name   = target.name;

    this.setState({
      [name]: value,
    })
  };

  /**
   * Keep track of the user agreeing to the terms
   *
   * If agreed to they can proceed
   *
   * @param event
   */
  handleAgreeToTermsChange = (event) => {
    this.setState({
      agreeToTerms: ! this.state.agreeToTerms,
    } );
  };

  /**
   *
   * @param event
   */
  handleEmailReset = (event) => {
    this.setState({
      promptID: 3,
      email: '',
      processingStatus: 'New User Registration'
    } );
  };

  /**
   *
   * @param event
   */
  handleThatsNotMe = (event) => {
    this.setState({
      promptID: 3,
      processingStatus: 'New User Registration'
    } );
  };

    

    handleSuggestionClick = ev => {
        this.setState({
            email: ev.currentTarget.value,
            suggestedEmail: null,
            suggestionChoice: ev.currentTarget.value,
            fileUploadErrors: [],
            promptID: null,
            processingStatus: 'Good to Run'
        },
            //once state is set
            () => {
                return this.userCheck();
            }
        );

    };

  /**
   * Confirm the visitor is using a work email in order to do the test
   *
   * @param event
   */
  handleEmailConfirmation( { serialized, fields, form } ) {

    if ( this.state.promptID === 1 ) {
      this.setState({
        promptID: 3,
      })

      return;
    }

      return this.userCheck();
    
    };

    userCheck() {
        var encodedEmail = encodeURIComponent(`${this.state.email}`);
        var urlCall = this.baseUrl + `/Users/Check?Email=`+encodedEmail+`&Filename=${this.state.fileName}&folderID=${this.state.folderID}`;
        if (this.state.suggestionChoice !== '') {
            urlCall = urlCall + '&noSuggest=true';
        }
        return fetch(urlCall, {
            method: 'GET',
        })
            .then((response) => {
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError("Oops, we haven't got JSON!");
                }
                return response.json();
            })
            .then((data) => {
                if (data.suggestedEmail !== null) {
                    this.setState({
                        fileName: this.state.fileName,
                        emailOK: data.emailOK,
                        suggestedEmail: data.suggestedEmail,
                        suggestionChoice: data.suggestedEmail,
                        processingStatus: "Did You Make a Typo?",
                        promptID: 4,
                        fileUploadErrors: ["We have a suggested correction to the email address you entered"]
                    })
                }

                else if (data.passedValidation === false) {
                    this.setState({
                        email: data.email,
                        contactID: data.contactID,
                        promptID: data.promptID,
                        prompt: data.prompt,
                        processStatus: "More Information Needed",
                        fileUploadErrors: (data.rejectedReason === null) ? [] : [data.rejectedReason],
                        passedValidation: data.passedValidation
                    })

                } else {
                    this.setState(
                        {
                            records: data.records,
                            lcUserID: data.lcUserID,
                            lcFileID: data.lcFileID,
                            fileName: this.state.fileName,
                            email: this.state.email,
                            contactID: data.contactID,
                            passedValidation: data.passedValidation,
                            prompt: data.prompt,
                            promptID: data.promptID,
                            processingStatus: 'Processing Your List',
                            rejectedReason: data.rejectedReason,
                            successMessage: data.successMessage,
                            fileUploadErrors: [],
                            showWhiteLabelPrompt: data.showWhiteLabelPrompt,
                        }
                    )
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                // Set error state
                this.setState({
                    fileUploadErrors: ['There was an error with your file.  Please try to submit again.'],
                    passedValidation: false,
                    processingStatus: 'Warning',
                    loading: false,
                });
            });
    };

  /**
   * Handle when a user is being registered. This is the actual submission of the form
   *
   * @param event
   */
    handleUserRegister( event ) {      
    event.preventDefault();
    // to prevent dup. submissions if one is already pending
    if (this.state.isBtnDisabled) {
        console.log('form submit disabled');
        return;
    }

    this.setState({
        isBtnDisabled: true
    })

    // fileName and folderID are required in order to be processed.
    // if not defined we should die early.
    if ( ! this.state.fileName || ! this.state.folderID ) {
      this.setState({
        uploadError:true,
      } )

      return;
    }

    var formData = new FormData();

    // Only pass a ContactID if we have one (Found in the FA CRM)
    if ( this.state.contactID ) {
      formData.append('contactID', this.state.contactID );
    }

    formData.append( 'fileName', this.state.fileName );
    formData.append( 'folderID', this.state.folderID );

    return fetch(this.baseUrl + '/Users/Register', {
      method: 'POST',
      body:formData
    })
    .then((response) => {
        if (response.ok) {
            this.setState({
            //lcUserID: data.lcUserID,
            //lcFileID: data.lcFileID,
            registerSuccess: true,
            promptID: '',
            prompt: '',
            processingStatus: 'Processing Your List',
            successMessage: "We are now processing your file. We will email your Free List Check report to " + this.state.email + " when completed. Thanks for using Free List Check!",
            fileUploadSuccess: '', // Clear our file Upload Success
            isBtnDisabled: false,
            } )
        }
    })
      .catch((error) => {
          console.error('Error:', error);
          alert("Oops, there was an error.");
      });
  }

  /**
   * Handle when a user is unknown and being registered.
   *
   * @param serialized
   * @param fields
   * @param form
   * @return {Promise<any>}
   */
  handleNewUserRegistration( { serialized, fields, form } ) {
    // to prevent dup. submissions if one is already pending
    if (this.state.isBtnDisabled) {
        console.log('form submit disabled');
        return;
    }

    // fileName and folderID are required in order to be processed.
    // if not defined we should die early.
    if ( ! this.state.fileName || ! this.state.folderID ) {
      this.setState({
        uploadError:true,
      } )

      return;
    }

    this.setState({
      isBtnDisabled: true,
      'promptID': 3,
      'prompt': 'New Registration',
    } )

    var formData = new FormData();

    // Only pass a ContactID if we have one (Found in the FA CRM).
    if ( this.state.contactID ) {
      formData.append('contactID', this.state.contactID );
    }

    formData.append( 'filename', this.state.fileName );
    formData.append( 'folderID', this.state.folderID );
    formData.append( 'email', this.state.email );
    formData.append( 'first', this.state.first );
    formData.append( 'last', this.state.last );
    formData.append( 'company', this.state.company );
    formData.append( 'title', this.state.title );
    formData.append( 'listsize', this.state.listsize );
    formData.append( 'phone', this.state.phone );
    formData.append( 'HeardFrom', this.state.HeardFrom );
    formData.append( 'comment', this.state.comment );
    formData.append( 'mktg', this.state.mktg );

    return fetch(this.baseUrl + '/Users/Register', {
      method: 'POST',
      body:formData
    })
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if ( ! contentType || ! contentType.includes('application/json')) {
          throw new TypeError("Oops, we haven't got JSON!");
        }
        return response.json();
      })
      .then((data) => {

        if ( ! data.error && data.successMessage ) {
          this.setState({
            lcUserID: data.lcUserID,
            lcFileID: data.lcFileID,
            promptID: '',
            prompt: '',
            successMessage: data.successMessage,
            fileUploadSuccess: '', // Clear our file Upload Success
            processingStatus: "Success",
            isBtnDisabled: false,
          } )
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

	render( props ) {
		return (
			<div id="list-check-container" className="layered-gradient-blue">
				<div className="padding-all medium-padding-all-large">
					<div className="grid-x align-center">
						<div id="list-check" className="small-12 medium-10 large-7 cell text-center background-white">

              <ListUploadInfo
                emailRecords={this.state.emailRecords}
                processingStatus={this.state.processingStatus}
                prompt={this.state.prompt}
                fileUploadErrors={this.state.fileUploadErrors}
                passedValidation={this.state.passedValidation}
                files={this.state.files}
              />

              { this.state.successMessage &&
			  	<ListUploadSuccess
					successMessage={this.state.successMessage}
					baseUrl={this.baseUrl}
					showWhiteLabelPrompt={this.state.showWhiteLabelPrompt}
					lcFileID={this.state.lcFileID}
					fileName={this.state.fileName}
				/>
              }

              { this.state.fileUploadSuccess === true ? (
                  <ListUploadProceed
				    baseUrl={this.baseUrl}
                    email={this.state.email}
                    emailOK={this.state.emailOK}
                    agreeToTerms={this.state.agreeToTerms}
                    emailRecords={this.state.emailRecords}
                    successMessage={this.state.successMessage}
                    passedValidation={this.state.passedValidation}
                    fileUploadErrors={this.state.fileUploadErrors}
                    prompt={this.state.prompt}
                    promptID={this.state.promptID}
					showWhiteLabelPrompt={this.state.showWhiteLabelPrompt}
					lcFileID={this.state.lcFileID}
					fileName={this.state.fileName}
                    handleEmailAddressChange={this.handleEmailAddressChange}
                    handleAgreeToTermsChange={this.handleAgreeToTermsChange}
                    handleEmailConfirmation={this.handleEmailConfirmation}
                    handleUserRegister={this.handleUserRegister}
                    handleInputChange={this.handleInputChange}
                    handleNewUserRegistration={this.handleNewUserRegistration}
                    handleEmailReset={this.handleEmailReset}
                    handleThatsNotMe={this.handleThatsNotMe}
                    handleSuggestionClick={this.handleSuggestionClick}
                                    
                    first={this.state.first}
                    last={this.state.last}
                    company={this.state.company}
                    title={this.state.title}
                    listsize={this.state.listsize}
                    phone={this.state.phone}
                    HeardFrom={this.state.HeardFrom}
                    comment={this.state.comment}
                    mktg={this.state.mktg}
                    suggestedEmail={this.state.suggestedEmail}
                    suggestionChoice={this.state.suggestionChoice}
                  />
              ) : (
                <ListUploadStart
                        files={this.state.files}
                        fileUploadErrors={this.state.fileUploadErrors}
                        prompt={this.state.prompt}
                        successMessage={this.state.successMessage}
                        loading={this.state.loading}
                        onFileDrop={this.onFileDrop}
                        handleUploadReset={this.handleUploadReset}
                        handleFileUpload={this.handleFileUpload}
                        handleSuggestionClick={this.handleSuggestionClick}
                />
              )
              }
                { // Show a retry button
                    this.state.fileUploadErrors.length > 0 && this.state.fileUploadErrors !== 'Your email address is invalid' && this.state.suggestedEmail === null &&
                    <button className="button margin-vertical-large" onClick={this.handleUploadReset}>Try Another File</button>
                }

                            {//upload another button
                                this.state.processingStatus === "Processing Your List" && this.state.fileUploadSuccess === true &&
                                <button className="button" onClick={this.handleUploadReset}>Upload Another List</button>
                            }

						</div>
					</div>
				</div>

				<div className="curved-separator"></div>
			</div>
		);
	}
}

export default ListUpload;
