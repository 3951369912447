import React from 'react'
import {Link} from "gatsby"
import {Form} from 'react-advanced-form'

import Terms from "./terms.js"
import Modal from 'react-responsive-modal';

/* Fields */
import Input from './fields/Input'

const isEmail = ( email ) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( email )
}

const validationRules = {
  extend: false, // when "true", merges custom- and application rules together
  type: {
    email: ({ value }) => isEmail(value),
  },
}

const validationMessages = {
  type: {
    email: {
      invalid: 'Please enter a valid company/business email address',
    },
  },
};

export default class ListUploadEmailForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};
	}

	onOpenModal = () => {
		this.setState({open: true});
	};

	onCloseModal = () => {
		this.setState({open: false});
	};

	render() {
		const {open} = this.state;

		const Prompt = props => {
		  if ( ! this.props.promptID ) {
		    return <p className="step-header"><span className="large">2.</span> <span className="step-label">Enter Your Email Address</span></p>
      } else {
        if ( this.props.promptID !== 1 ) {
          return <p className="step-header"><span className="large">2.</span> <span className="step-label">Please Update Your Email Address</span></p>
        } else {
          return <p className="step-header"><span className="large">2.</span> <span className="step-label">{this.props.prompt}</span></p>
        }
      }
    }

		return (
      <div>
        <Prompt prompt={this.props.prompt} promptID={this.props.promptID} />

        <Form
          className="margin-bottom"
          action={this.props.handleEmailConfirmation}
          rules={validationRules}
          messages={validationMessages}>

          <div className="hide-label input-text-center">
            <Input
              type="email"
              key="email"
              id="email"
              name="email"
              label="Email Address"
              placeholder="Email Address"
              value={this.props.email}
              onChange={this.props.handleEmailAddressChange}
              required />
          </div>

          <div className="padding-all-small agree-container">
            <label htmlFor="agreeToTerms">
              <input type="checkbox" id="agreeToTerms" name="agreeToTerms" value="1" checked={this.props.agreeToTerms} onChange={this.props.handleAgreeToTermsChange} />
              I agree to the <span className="modal-link" onClick={this.onOpenModal}>terms of service</span>
            </label>
          </div>

          <div className="padding-all-small">
            <button type="submit" disabled={ ! this.props.agreeToTerms }>{( this.props.promptID === 1 ) ? 'Yes ' : '' }Proceed</button>
            {
              this.props.promptID === 1 && <span> <button type="button" className="alert" onClick={this.props.handleResetEmail}>No</button></span>
            }
            </div>
        </Form>

          <div className="small">
            <p>
              <strong>Why Do You Need My Email Address?</strong><br />
				We send you a link to your Free List Check Report via email, so make sure your email address is correct! Non-corporate email addresses are subject to manual review, so use a corporate address for fastest processing time!
            </p>
          </div>
          <Modal open={this.state.open} onClose={this.onCloseModal}>
            <Terms />
          </Modal>
			</div>
		);
	}
}
