import React from 'react'

class ListUploadConfirmIdentity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isBtnDisabled: false
        }
    }

	render() {
		return (
			<div className="padding-all-small">
				<p>
					<strong>One More Step</strong><br />
					Please confirm your identity below to process your file.
				</p>
				<p>{this.props.prompt}</p>
				<p><button onClick={this.props.handleUserRegister}>Yes, Process My List</button> <button className="alert" onClick={this.props.handleThatsNotMe}>That's Not Me</button></p>
			</div>
		);
	}
}

export default ListUploadConfirmIdentity;
