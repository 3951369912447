import React from 'react'
import ListUploadEmailForm from './list-upload-email-form'
import ListUploadConfirmIdentity from "./list-upload-confirm-identity";
import ListUploadRegister from "./list-upload-register";

export default class ListUploadProceed extends React.Component {

    render() {

        let orStyle = {
            color: "gray",
            paddingTop: "20px"
        }


		return (
      <div className="padding-all-small">
        { ( ! this.props.promptID || this.props.promptID === 1 ) && ! this.props.successMessage &&
          <ListUploadEmailForm
            prompt={this.props.prompt}
            promptID={this.props.promptID}
            email={this.props.email}
            agreeToTerms={this.props.agreeToTerms}
            passedValidation={this.props.passedValidation}
            handleResetEmail={this.props.handleEmailReset}
            handleEmailAddressChange={this.props.handleEmailAddressChange}
            handleAgreeToTermsChange={this.props.handleAgreeToTermsChange}
            handleEmailConfirmation={this.props.handleEmailConfirmation}
          />
        }

        { this.props.promptID === 2 && ! this.props.successMessage &&
          <ListUploadConfirmIdentity
            prompt={this.props.prompt}
            promptID={this.props.promptID}
            passedValidation={this.props.passedValidation}
            handleProcessList={this.props.handleProcessList}
            handleUserRegister={this.props.handleUserRegister}
            handleThatsNotMe={this.props.handleThatsNotMe}
          />
        }

        { ( this.props.promptID === 3 && ! this.props.successMessage ) &&
        <div>
          <ListUploadRegister
            prompt={this.props.prompt}
            promptID={this.props.promptID}
            email={this.props.email}
            first={this.props.first}
            last={this.props.last}
            company={this.props.company}
            mktg={this.props.mktg}
            title={this.props.title}
            listsize={this.props.listsize}
            phone={this.props.phone}
            HeardFrom={this.props.HeardFrom}
            comment={this.props.comment}
            handleEmailAddressChange={this.props.handleEmailAddressChange}
            handleInputChange={this.props.handleInputChange}
            handleNewUserRegistration={this.props.handleNewUserRegistration}
			showWhiteLabelPrompt={this.props.showWhiteLabelPrompt}
			lcFileID={this.props.lcFileID}
			fileName={this.props.fileName}
			baseUrl={this.props.baseUrl}
          />
        </div>
        }

                {
                    (this.props.promptID === 4) &&
                    <div>
                        <p>Use the corrected email address</p>
                        <button key="suggestion" value={this.props.suggestedEmail} onClick={this.props.handleSuggestionClick}>{this.props.suggestedEmail}</button>
                        {this.props.emailOK == false
                            ?
                                <p style={orStyle}>The email you entered ({this.props.email}) is not valid to register with.</p>
                            :
                            <div>
                                <p style={orStyle}>or</p>
                                <p>Use the email address I entered</p>
                                <button key="suggestionOriginal" value={this.props.email} onClick={this.props.handleSuggestionClick}>Use {this.props.email}</button>
                            </div>
                        }
                        
                    </div>
                }
      </div>
		);
	}
}
